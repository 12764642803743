import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import AppContext from '../Context/AppContext';
import Login from './Screens/AuthScreens/Login/Login';
import Register from './Screens/AuthScreens/Register/Register';
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne';
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree';
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen';
import InviteEmployees from './Screens/UsersScreen/Employees/InviteEmployees/InviteEmployees';
import NavBar from './Partials/Sections/NavBar/NavBar';
import Loader from './Partials/Elements/Loader/Loader';
import Dashboard from './Screens/Dashboard/Dashboard';
import SideBar from './Partials/Sections/SideBar/SideBar';
import UsersScreen from './Screens/UsersScreen/UsersScreen';
// import EmployeeScreen from './Screens/AuthScreens/Employees/EmployeeScreen';
import SocialMediaScreen from './Screens/AppSettingsScreens/SocialMediaScreen/SocialMediaScreen';

import HeroSliders from './Screens/AppSettingsScreens/HeroSliders/HeroSliders';
import AppSettingsScreens from './Screens/AppSettingsScreens/AppSettingsScreens';

import Ads from './Screens/AppSettingsScreens/Ads/Ads';
import University from './Screens/University/University';
import UniversityManagers from './Screens/University/UniversityManagers/UniversityManagers';
import UniversityScreens from './Screens/University/UniversityScreens/UniversityScreens';
import Managers from './Screens/UsersScreen/Managers/Managers';
import Employees from './Screens/UsersScreen/Employees/Employees';

function App() {
  const { employee, isLoading } = useContext(AppContext);

  return (
    <section>
      <BrowserRouter>
        <NavBar />

        <main>
          {employee && <SideBar />}
          <section className='screen-container'>
            <Routes>
              <Route
                path='/login'
                element={!employee ? <Login /> : <Navigate to={'/'} />}
              />
              <Route
                path='/register/:token'
                element={!employee ? <Register /> : <Navigate to={'/'} />}
              />
              <Route path='/recover-pass-one' element={<RecoverPassOne />} />
              <Route
                path='/resetEmployeePassword/:token'
                element={<RecoverPassThree />}
              />

              <Route
                path='/employeesScreen'
                element={
                  employee ? <EmployeesScreen /> : <Navigate to={'/login'} />
                }
              />

              {/* <Route
                path='/employeeScreen'
                element={
                  employee ? <EmployeeScreen /> : <Navigate to={'/login'} />
                }
              /> */}

              <Route
                path='/usersScreen'
                element={
                  employee ? <UsersScreen /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/employeeInvites'
                element={
                  employee ? <InviteEmployees /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/employees'
                element={employee ? <Employees /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/managers'
                element={employee ? <Managers /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/'
                element={employee ? <Dashboard /> : <Navigate to={'/login'} />}
              />

              {/* appp setting */}
              <Route
                path='/appSettings'
                element={
                  employee ? <AppSettingsScreens /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/socialLinks'
                element={
                  employee ? <SocialMediaScreen /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/heroSliders'
                element={
                  employee ? <HeroSliders /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/ads'
                element={employee ? <Ads /> : <Navigate to={'/login'} />}
              />

      
              <Route
                path='/university'
                element={employee ? <University /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/universityScreen/:universityName/:parentID'
                element={
                  employee ? <UniversityScreens /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/university/:universityID/:universityName/:level'
                element={
                  employee ? <UniversityManagers /> : <Navigate to={'/login'} />
                }
              />
            </Routes>
            
          </section>
        </main>
      </BrowserRouter>
      <ToastContainer />

      {isLoading && <Loader />}
    </section>
  );
}

export default App;
