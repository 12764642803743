import { useEffect, useState } from 'react';
import './Dashboard.css';
import DashboardWrapper from './DashboardWrapper';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import DashboardCard from './DashboardCard';

function Dashboard() {
  const axiosInstance = useAxiosInstance();
  const [totalManager, setTotalManager] = useState([]);
  const [totalUniversity, setTotalUniversity] = useState([]);
  const [totalCaptain, setTotalCaptain] = useState([]);

  useEffect(() => {
    async function setAndFetchEventsInfo() {
      const { data } = await axiosInstance.get('/analytics/mgrCaptainAnalytics');
      setTotalManager(data.totalManagers);
      setTotalUniversity(data.totalUniversities);
      setTotalCaptain(data.totalCaptain);
    }
    setAndFetchEventsInfo();
  }, [axiosInstance]);
  return (
    <DashboardWrapper>
      <DashboardCard title={'Total Managers'} count={totalManager} />
      <DashboardCard title={'Total universities'} count={totalUniversity} />
      <DashboardCard title={'Total Captains'} count={totalCaptain} />
    </DashboardWrapper>
  );
}

export default Dashboard;
