import axios from "axios";
import { useContext, useMemo } from "react";
import { toast } from "react-toastify";
import AppContext from "../../Context/AppContext";

function useAxiosInstance() {
  const { employee, logout } = useContext(AppContext);

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL + "api/employeeApp/protected/",
      headers: {
        Authorization: "bearer " + employee?.access_token,
        // Authorization: "Bearer " + employee?.token,
      },
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // toast.error("Please login again,Your Token is expired")
          logout();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [employee, logout]);

  return axiosInstance;
}

export default useAxiosInstance;
