import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';
import { FaUniversity } from 'react-icons/fa';
import {
  RiCalendarEventLine,
  RiDirectionLine,
  RiGroupLine,
  RiHome2Line,
  RiMoneyDollarBoxLine,
  RiOrderPlayLine,
  RiSettings2Line,
  RiStackLine,
} from 'react-icons/ri';

import './SideBar.css';

function SideBar() {
  return (
    <>
      <section className='small_sidebar'>
        <div className='container'>
          <SmallSideBarItem link='/' dataTip='Home Page' dataFor='dashboard'>
            <RiHome2Line />
          </SmallSideBarItem>

          <SmallSideBarItem link='/usersScreen' dataTip='Users' dataFor='Users'>
            <RiGroupLine />
          </SmallSideBarItem>

          <SmallSideBarItem
            link='/university'
            dataTip='University'
            dataFor='University'
          >
            <FaUniversity />
          </SmallSideBarItem>

          {/* <SmallSideBarItem
            link='/appSettings'
            dataTip='App Settings'
            dataFor='App Settings'
          >
            <RiSettings2Line />
          </SmallSideBarItem> */}

        </div>
      </section>
    </>
  );
}

export default SideBar;
